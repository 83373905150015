import Head from 'next/head';
import { SITE_DOMAIN } from '@/utils/constants';

export default function SEO({
  title = 'FontVisual',
  description = 'Create font previews for your website or Shopify. Choosing fonts made easy for your customers.',
  url = SITE_DOMAIN,
  image = 'https://fontvisual.com/fontvisualcover.jpg',
  creator = 'FontVisual',
  keywords = 'font preview tool for website, font preview tool for shopify, live font preview, font preview online, wordpress fonts preview, font preview generator, craft fonts'
}) {
  return (
    <Head>
      {/* Primary Meta Tags */}
      <title key="title">{title}</title>
      <link key="canonical" rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph / Facebook */}
      <meta name="og:type" property="og:type" content="website" />
      <meta name="og:url" property="og:url" content={url} />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta name="og:image" property="og:image" content={image} />

      {/* Twitter */}
      <meta name="twitter:dnt" content="on" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:creator" content={creator} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Head>
  );
}
