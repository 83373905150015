/** @jsxRuntime classic /
/** @jsx jsx */
import React from 'react';
import { useColorMode, Box } from '@chakra-ui/react';
import { jsx } from '@emotion/react';

const Logo = (props) => {
  const { colorMode } = useColorMode();

  // return <image src="/favicon.ico" alt="FontVisual"></image>;

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={100}
      viewBox="0 0 52.917 26.458"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          x={0}
          width={1}
          y={0}
          height={1.001}
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation={0.002} />
        </filter>
      </defs>
      <g
        aria-label="fontvisual"
        transform="translate(-75.768 -94.258) scale(.42907)"
        style={{
          lineHeight: 0,
          InkscapeFontSpecification: "'Pacifico Bold'",
          textAlign: 'center'
        }}
        fontWeight={700}
        fontSize={21.167}
        fontFamily="Pacifico"
        textAnchor="middle"
        fill="#ffa505"
        strokeWidth={0.265}
        filter="url(#prefix__a)"
      >
        <path
          d="M216.12 251.19q.593 0 .593.888 0 1.672-2.625 1.672-.508 0-1.46-.19.275 1.312.444 2.92.19 1.588.19 2.626 0 2.286-.74 3.492-.74 1.207-1.947 1.207-1.524 0-2.18-2.16-.635-2.158-.635-6.519 0-4.064.867-8.234.89-4.19 2.413-6.921 1.546-2.752 3.387-2.752.995 0 1.545.89.572.888.572 2.391 0 4.974-5.948 10.837-.212 2.075-.212 3.641 0 2.18.148 4.466.149 2.307.487 2.307.635 0 .635-2.201 0-1.651-.233-3.196-.211-1.545-.592-3.26-.021-.275.17-.698.21-.424.507-.741.296-.318.53-.318.423 0 1.015.043.19.02.445.02.846 0 1.884-.147.169-.022.36-.043.19-.02.38-.02zm-2.137-11.875q-.381 0-.953 1.29-.55 1.292-1.1 3.43-.55 2.116-.953 4.487 1.693-1.926 2.603-4 .91-2.096.932-3.916 0-1.291-.53-1.291z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M225.911 250.618q.276 0 .424.275.148.275.148.698 0 1.016-.614 1.207-1.27.444-2.794.508-.402 1.778-1.587 2.857-1.186 1.059-2.689 1.059-1.27 0-2.18-.614-.889-.614-1.355-1.63-.465-1.016-.465-2.201 0-1.609.614-2.858.613-1.27 1.693-1.968 1.08-.72 2.392-.72 1.609 0 2.582 1.122.995 1.1 1.164 2.73.995-.063 2.371-.423.17-.042.296-.042zm-6.942 4.36q.677 0 1.164-.55.508-.55.677-1.588-.656-.444-1.016-1.164-.338-.72-.338-1.524 0-.339.063-.677h-.106q-.846 0-1.418.825-.55.804-.55 2.286 0 1.164.444 1.778.466.614 1.08.614z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M226.169 257.222q-.804 0-1.143-.847-.318-.847-.318-2.71 0-2.751.784-5.228.19-.613.613-.889.445-.296 1.228-.296.423 0 .593.106.17.106.17.402 0 .339-.318 1.524-.212.847-.339 1.482-.127.635-.212 1.566.699-1.82 1.567-2.963t1.693-1.63q.847-.487 1.545-.487 1.376 0 1.376 1.376 0 .826-.466 2.985-.402 1.841-.402 2.434 0 .846.614.846.423 0 .995-.508.593-.529 1.566-1.693.254-.296.572-.296.275 0 .423.254.17.254.17.698 0 .847-.403 1.313-.91 1.121-1.968 1.841-1.037.72-2.371.72-1.08 0-1.63-.614-.55-.635-.55-1.82 0-.593.296-2.117.275-1.334.275-1.842 0-.338-.232-.338-.276 0-.784.72-.487.698-1.016 1.862-.508 1.164-.825 2.455-.233.995-.55 1.355-.297.339-.953.339z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M244.607 252.396q.275 0 .424.254.169.254.169.698 0 .847-.402 1.313-.91 1.121-1.99 1.841t-2.477.72q-4.318 0-4.318-6.075 0-.931.064-1.884h-.826q-.635 0-.867-.233-.212-.233-.212-.74 0-1.186.952-1.186h1.207q.36-2.328 1.1-4.255.741-1.926 1.779-3.069 1.058-1.143 2.265-1.143.889 0 1.397.783.507.784.507 1.969 0 3.28-2.751 5.715h2.37q.34 0 .487.148.148.148.148.55 0 1.461-2.391 1.461h-2.583q-.042 1.058-.042 1.651 0 2.201.508 3.09.53.89 1.651.89.91 0 1.609-.551.698-.55 1.65-1.651.255-.296.572-.296zm-3.704-11.727q-.318 0-.72.805-.38.783-.74 2.201-.34 1.397-.572 3.111 1.249-1.079 1.863-2.413.635-1.354.635-2.455 0-1.249-.466-1.249z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M254.574 250.681q.064-.021.212-.021.317 0 .487.212.169.211.169.571 0 .656-.254 1.037-.254.36-.762.53-.974.317-2.074.317-.932 0-1.757-.254-.614.995-1.355 2.053-.847 1.207-1.46 1.651-.614.445-1.397.445-.868 0-1.376-.678-.487-.677-.614-2.138-.254-2.963-.254-5.185v-.741q.021-.699.38-.974.36-.275 1.08-.275.55 0 .805.254.275.233.275.804 0 2.434.296 6.33 1.27-1.885 1.905-3.007-.317-.613-.317-1.46 0-.72.317-1.397.318-.677.868-1.1.55-.424 1.249-.424.614 0 .995.444.38.424.38 1.25 0 .952-.507 2.18.804-.043 2.138-.318z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M256.378 245.855q-.89 0-1.334-.402-.444-.423-.444-1.164 0-.741.571-1.228.593-.508 1.46-.508.784 0 1.27.381.488.381.488 1.08 0 .846-.55 1.354-.551.487-1.461.487zm-.17 11.367q-1.375 0-2.01-.974-.614-.974-.614-2.582 0-.953.233-2.435.254-1.502.635-2.794.19-.677.508-.93.317-.255 1.016-.255 1.08 0 1.08.72 0 .529-.403 2.455-.508 2.328-.508 3.154 0 .635.17.974.169.338.57.338.382 0 .953-.529.572-.529 1.524-1.672.254-.296.572-.296.275 0 .423.254.17.254.17.698 0 .847-.403 1.313-2.095 2.56-3.915 2.56z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M262.751 257.687q-1.1 0-1.693-.508-.572-.508-.572-1.143 0-.55.402-.952.402-.402 1.186-.402.275 0 .635.063l.571.064q-.021-.55-.254-1.037-.212-.487-.55-.932-.339-.466-.635-.804-.656 1.249-1.313 2.074-.635.826-1.397 1.567-.38.38-.804.38-.339 0-.55-.232-.212-.254-.212-.614 0-.423.296-.783l.276-.339q1.164-1.44 1.756-2.37.36-.615.847-1.63.487-1.038.953-2.138.402-.932 1.672-.932.593 0 .825.106.233.106.233.339 0 .127-.085.402-.084.275-.232.55-.381.762-.381 1.291 0 .318.211.699.233.381.699.952.677.89 1.016 1.524.36.614.36 1.355 0 .212-.043.593 1.038-.402 2.435-2.138.254-.296.571-.296.275 0 .423.254.17.254.17.698 0 .805-.402 1.313-1.059 1.312-2.032 1.799-.953.465-2.371.508-.847.72-2.011.72z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M270.027 257.222q-.953 0-1.567-.868-.592-.89-.592-2.201 0-1.588.211-2.9.212-1.334.699-2.816.212-.635.593-.91.38-.275 1.206-.275.466 0 .635.148.19.148.19.445 0 .17-.232 1.143-.212.783-.339 1.418-.423 2.222-.423 2.963 0 .445.106.635.105.17.338.17.318 0 .783-.635.487-.635 1.016-1.927.55-1.29 1.059-3.175.17-.635.508-.91.36-.275 1.058-.275.487 0 .677.127.19.106.19.423 0 .53-.528 2.9-.593 2.71-.593 3.345 0 .402.17.635.169.211.444.211.423 0 .995-.508.592-.529 1.566-1.693.254-.296.572-.296.275 0 .423.254.17.254.17.698 0 .847-.403 1.313-.91 1.121-1.968 1.841-1.038.72-2.371.72-1.016 0-1.503-.783-.466-.784-.466-2.202-.36 1.44-1.08 2.223-.719.762-1.544.762z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M280.27 257.222q-1.313 0-2.096-.953-.784-.952-.784-2.497 0-1.694.784-3.197.783-1.524 2.074-2.434 1.312-.931 2.773-.931.465 0 .614.19.169.17.275.635.444-.084.931-.084 1.037 0 1.037.74 0 .445-.317 2.117-.487 2.434-.487 3.387 0 .317.148.508.17.19.423.19.403 0 .974-.508.572-.529 1.545-1.693.254-.296.572-.296.275 0 .423.254.17.254.17.698 0 .847-.403 1.312-.868 1.08-1.841 1.82-.974.742-1.884.742-.699 0-1.291-.466-.572-.487-.868-1.312-1.1 1.778-2.773 1.778zm.761-2.138q.466 0 .89-.55.422-.55.613-1.461l.783-3.895q-.889.022-1.65.678-.742.635-1.186 1.693-.445 1.058-.445 2.244 0 .656.254.973.275.318.741.318z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
        <path
          d="M293.746 254.131q.233-.211.466-.211.254 0 .402.296.17.275.17.677 0 .89-.636 1.419-1.1.91-2.498.91-2.01 0-2.942-1.672-.931-1.694-.931-4.403 0-2.582.656-5.885.677-3.302 1.969-5.672 1.312-2.37 3.111-2.37 1.016 0 1.588.952.592.931.592 2.688 0 2.519-1.397 5.842-1.376 3.323-3.767 6.562.148.867.529 1.248.38.381 1.037.381.36 0 .804-.211.466-.212.847-.55zm-.699-14.816q-.486 0-1.1 1.756-.614 1.757-1.08 4.36-.465 2.604-.508 4.996 1.503-2.476 2.392-4.953.89-2.498.89-4.55 0-1.61-.593-1.61z"
          style={{
            InkscapeFontSpecification: "'Pacifico Bold'"
          }}
        />
      </g>
      <g fill="#ffa505" strokeMiterlimit={10} stroke="#fff">
        <path
          d="M4.399 12.064a9.722 9.722 0 012.019-1.167 7.663 7.663 0 011.637-.504c1.106-.197 1.987-.023 2.32.551h0A4.566 4.566 0 006.417 8.66c-.664 0-1.255.677-1.637 1.733a7.636 7.636 0 00-.382 1.671z"
          strokeWidth={0.08052462}
        />
        <path
          d="M7.868 13.23a3.194 3.194 0 00-.52-.485 4.024 4.024 0 010 .968 3.16 3.16 0 01-.159.692c-.158.438-.403.719-.679.719.701 0 1.314-.381 1.641-.947h0c.138-.239.017-.592-.283-.948z"
          strokeWidth={0.03345177}
        />
        <path
          d="M1.85 13.23c0 .83.222 1.61.61 2.284h0c.332.576 1.213.748 2.319.552.51-.09 1.065-.26 1.637-.505a9.724 9.724 0 01-2.019-1.167 7.632 7.632 0 01-1.255-1.166c-.722-.86-1.014-1.71-.682-2.284h0a4.56 4.56 0 00-.61 2.285z"
          strokeWidth={0.08052462}
        />
        <path
          d="M8.151 12.282a1.893 1.893 0 00-1.64-.947c-.276 0-.521.28-.68.718a3.17 3.17 0 00-.158.692 4.031 4.031 0 01.837-.483 3.14 3.14 0 01.68-.21c.458-.08.823-.008.961.23z"
          strokeWidth={0.03345177}
        />
        <path
          d="M6.418 17.798c1.69 0 3.167-.919 3.956-2.284h0c.332-.575.04-1.424-.681-2.283a7.702 7.702 0 00-1.256-1.167 9.704 9.704 0 010 2.334 7.62 7.62 0 01-.382 1.67c-.383 1.053-.973 1.73-1.637 1.73z"
          strokeWidth={0.08052462}
        />
        <path
          d="M6.51 14.196a4.032 4.032 0 01-.837-.484 3.165 3.165 0 01-.52-.483c-.3-.357-.42-.71-.283-.947h0a1.887 1.887 0 00-.253.947c0 .346.092.668.253.948h0c.137.238.503.31.961.228.212-.038.442-.107.68-.209z"
          strokeWidth={0.03345177}
        />
      </g>
    </Box>
  );
};

export default Logo;
