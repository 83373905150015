import React from 'react';
import NextLink from 'next/link';
import {
  Box,
  Flex,
  Text,
  Button,
  useToast,
  MenuGroup,
  IconButton,
  MenuItem as ChakraMenuItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Icon,
  Tooltip
} from '@chakra-ui/react';
import {
  MdAccountCircle,
  MdOutlineContactMail,
  MdExitToApp,
  MdOutlineReceipt,
  MdOutlineSettings,
  MdHelpOutline
} from 'react-icons/md';
import Logo from '@/components/Logo';
import { useUser } from '@/utils/useUser';
import { useRouter } from 'next/router';

const MenuItem = ({ children, isLast, to = '/', ...rest }) => {
  return (
    <Text
      fontSize="md"
      fontWeight="semibold"
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <NextLink href={to} passHref>
        {children}
      </NextLink>
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="#4e3000"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#4e3000"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = (props) => {
  const { user, session, signOut } = useUser();
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);
  const toast = useToast();
  const router = useRouter();

  const handleSignout = async () => {
    // console.log('handleSignout');
    const { error } = await signOut();
    if (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    } else {
      router.push('/');
    }
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      // py={4}
      px={10}
      bg={['primary.50', 'primary.50', 'transparent', 'transparent']}
      // boxShadow={'lg'}
      // color={['primary.800', 'primary.800', 'primary.800', 'primary.800']}
      {...props}
    >
      <Flex
        align="center"
        as="a"
        href="/"
        aria-label="FontVisual, Back to homepage"
      >
        <Logo
          w="150px"
          color={['white', 'white', 'primary.500', 'primary.500']}
        />
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? 'block' : 'none', md: 'block' }}
        flexBasis={{ base: '100%', md: 'auto' }}
      >
        <Flex
          align="center"
          justify={['center', 'space-between', 'flex-end', 'flex-end']}
          direction={['column', 'row', 'row', 'row']}
          pt={[4, 4, 0, 0]}
        >
          {user ? (
            <>
              {/* <MenuItem to="/fonts">Fonts </MenuItem> */}
              {/* <MenuItem to="/images">Images </MenuItem> */}
              {/* <MenuItem to="/showcases">Showcases </MenuItem> */}
              {/* <MenuItem to="/fonts" isLast> */}
              <Tooltip
                label="Help Center"
                aria-label="Help Center"
                fontSize="sm"
              >
                <IconButton
                  aria-label="Help Center"
                  mr="4"
                  variant="ghost"
                  icon={<MdHelpOutline size={24} />}
                  onClick={() =>
                    window.open('https://fontvisual.com/help', '_blank')
                  }
                />
              </Tooltip>
              <Tooltip
                label="Account Settings"
                aria-label="Account Settings"
                fontSize="sm"
              >
                <IconButton
                  aria-label="Account Settings"
                  mr="4"
                  variant="ghost"
                  icon={<MdOutlineSettings size={24} />}
                  onClick={() => router.push('/account')}
                />
              </Tooltip>
              <MenuItem to="/pricing">
                <Button
                  //   size="sm"
                  borderRadius="md"
                  py="4"
                  px="4"
                  lineHeight="1"
                  size="md"
                  color={['primary.500', 'primary.500', 'white', 'white']}
                  bg={['white', 'white', 'primary.500', 'primary.500']}
                  _hover={{
                    bg: [
                      'primary.100',
                      'primary.100',
                      'primary.600',
                      'primary.600'
                    ]
                  }}
                >
                  Upgrade
                </Button>
              </MenuItem>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="My Account"
                  icon={<MdAccountCircle size={36} />}
                  variant="ghost"
                />
                <MenuList>
                  <MenuGroup title={user.email} fontWeight="normal">
                    {/* <ChakraMenuItem fontSize="sm" cursor="default">
                      Signed in as {user.email}
                    </ChakraMenuItem> */}
                    <MenuDivider />
                    <ChakraMenuItem
                      icon={<MdOutlineSettings size={24} />}
                      onClick={() => router.push('/account')}
                    >
                      Account Settings
                    </ChakraMenuItem>
                    <ChakraMenuItem
                      icon={<MdOutlineReceipt size={24} />}
                      onClick={() => router.push('/billing')}
                    >
                      Billing
                    </ChakraMenuItem>
                    {/* <ChakraMenuItem
                      icon={<MdHelpOutline size={24} />}
                      onClick={
                        () =>
                          window.open('https://fontvisual.com/help', '_blank')
                        // router.push('https://fontvisual.com/contact')
                      }
                    >
                      Help Center
                    </ChakraMenuItem> */}
                    <ChakraMenuItem
                      icon={<MdOutlineContactMail size={24} />}
                      onClick={
                        () =>
                          window.open(
                            'https://fontvisual.com/contact',
                            '_blank'
                          )
                        // router.push('https://fontvisual.com/contact')
                      }
                    >
                      Support
                    </ChakraMenuItem>
                    <ChakraMenuItem
                      icon={<MdExitToApp size={24} />}
                      onClick={handleSignout}
                    >
                      Sign Out
                    </ChakraMenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
              {/* </MenuItem> */}
            </>
          ) : (
            <>
              {/* <MenuItem to="/blog">Blog </MenuItem> */}
              <MenuItem to="/pricing">Pricing </MenuItem>
              <MenuItem to="/signin" isLast>
                <Button
                  //   size="sm"
                  borderRadius="full"
                  py="4"
                  px="4"
                  lineHeight="1"
                  size="md"
                  color={['primary.500', 'primary.500', 'white', 'white']}
                  bg={['white', 'white', 'primary.500', 'primary.500']}
                  _hover={{
                    bg: [
                      'primary.100',
                      'primary.100',
                      'primary.600',
                      'primary.600'
                    ]
                  }}
                >
                  Sign In
                </Button>
              </MenuItem>
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
